import React, { useContext } from "react";
import _ from 'lodash';
import CmsContext from "CmsContext";
import pkg from "../../../../package.json";

function Version() {

  const cmsContext = useContext(CmsContext);
  const CMS_VARIABLES = cmsContext.findByType("VARIABLES");

  return (
    <p className="badge badge-primary text-white text-sm text-center">
      Version {_.get(CMS_VARIABLES, 'ORGANIZATION') === 'City of Las Piñas' ? pkg.versionLp
        : (
          pkg.version
        )}
    </p>
  );
}

Version.propTypes = {};

export default Version;